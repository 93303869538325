import { ESubscriptionActions, SubscriptionActions } from '../actions/subscription.actions';
import { initialISubscriptionState, ISubscriptionState } from '../states/subscription.state';

export function subscriptionReducers(
  state = initialISubscriptionState,
  action: SubscriptionActions,
): ISubscriptionState {
  switch (action.type) {
    case ESubscriptionActions.GetSubscriptionsSuccess: {
      return {
        ...state,
        subscriptions: action.payload,
      };
    }

    case ESubscriptionActions.UpdateLifeTimeProductStatus: {
      return {
        ...state,
        lifeTimeProductStatus: action.lifeTimeProductStatus,
      };
    }

    case ESubscriptionActions.UpdateOxfordSubscription: {
      return {
        ...state,
        oxfordWasSent: true,
      };
    }

    case ESubscriptionActions.UpdateE4KSubscription: {
      return {
        ...state,
        e4kWasSent: true,
      };
    }

    case ESubscriptionActions.UpdateSPSubscription: {
      return {
        ...state,
        spWasSent: true,
      };
    }

    case ESubscriptionActions.SetOrderIdCard: {
      return {
        ...state,
        orderIdCard: action.orderId,
      };
    }

    case ESubscriptionActions.SetOrderIdPaypal: {
      return {
        ...state,
        orderIdPaypal: action.orderId,
      };
    }

    case ESubscriptionActions.SetOrderId: {
      return {
        ...state,
        orderId: action.orderId,
      };
    }

    case ESubscriptionActions.SetErrorOrderId: {
      return {
        ...state,
        errorOrderId: action.errorOrderId,
      };
    }

    case ESubscriptionActions.SetAdditionalDiscount: {
      return {
        ...state,
        isAdditionalDiscount: true,
      };
    }

    case ESubscriptionActions.SetSelectedPaymentMethod: {
      return {
        ...state,
        selectedPaymentMethod: action.method,
      };
    }

    case ESubscriptionActions.SetOrderMethod: {
      return {
        ...state,
        orderMethod: action.method,
      };
    }

    default : return state;
  }
}
