import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'angular-2-local-storage';
import { GetUserInfoBrowserID } from 'src/app/store/actions/user-info.actions';
import { IAppState } from 'src/app/store/states/app.state';
import { Variant } from '../models/variant';

@Injectable()
export class StorageService {

  private readonly storageIDKey = '_IK_storage_';

  private readonly variantKey = '_LG_variant_';

  private readonly timerKey = '_LG_TIME';

  constructor(
    private localStorageService: LocalStorageService,
    private store: Store<IAppState>,
    ) {}

  public get isExist(): boolean {
    return this.localStorageService.get(this.storageIDKey);
  }

  public userIdExist(): boolean {
    return this.isExist;
  }

  public getUserId(): string {
    if (this.isExist) {
      const user: {userId: string} = JSON.parse(this.localStorageService.get(this.storageIDKey));
      if (user.userId) {
        this.store.dispatch(new GetUserInfoBrowserID(user.userId));
        return user.userId;
      }
    }
    const userId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const user: any = JSON.parse(this.localStorageService.get(this.storageIDKey));
    const refreshToken = user ? user.refreshToken : '';
    const email =  user ? user.email : '';
    this.localStorageService.set(this.storageIDKey, JSON.stringify({ userId, refreshToken, email }));
    this.store.dispatch(new GetUserInfoBrowserID(userId));
    return userId;
  }

  public setRefreshTokenAndEmail(refreshToken: string | null, email: string | null): void {
    let userId = '';
    if (this.isExist) {
      const user: {userId: string} = JSON.parse(this.localStorageService.get(this.storageIDKey));
      userId = user.userId;
    }
    this.localStorageService.set(this.storageIDKey, JSON.stringify({ userId, refreshToken, email }));
  }

  public setVariantName(variant: string): void {
    if (this.isExist) {
      const user: any = JSON.parse(this.localStorageService.get(this.storageIDKey));
      user.variant = variant;
      this.localStorageService.set(this.storageIDKey, JSON.stringify(user));
      return;
    }
    const userId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const user: any = {};
    const refreshToken = user ? user.refreshToken : '';
    const email =  user ? user.email : '';
    user.variant = variant;
    user.userId = userId;
    user.refreshToken = refreshToken;
    user.email = email;
    this.localStorageService.set(this.storageIDKey, JSON.stringify(user));
    this.store.dispatch(new GetUserInfoBrowserID(userId));
  }

  public getRefreshToken(): {refreshToken: string, email: string} | null {
    const token: {refreshToken: string, email: string} = JSON.parse(this.localStorageService.get(this.storageIDKey));
    return token;
  }

  public setVariant(variant: Variant): void {
    this.localStorageService.set(this.variantKey, JSON.stringify(variant));
    this.setTime();
  }

  public getVariant(): Variant | null {
    if (!this.checkTime()) return null;
    const info: any = JSON.parse(this.localStorageService.get(this.variantKey));
    if (info) {
      return info;
    }
    return null;
  }

  private checkTime(): boolean {
    const storageDate: Date = new Date(JSON.parse(this.localStorageService.get(this.timerKey)));
    if (!storageDate) return false;
    const date = new Date();
    return (date < storageDate);
  }

  private setTime(): void {
    const date = new Date();
    date.setDate(date.getDate() + 7); // + 7 дней
    this.localStorageService.set(this.timerKey, JSON.stringify(date));
  }
}
