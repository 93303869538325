import { AdditionPurchaseTypeEnum, ThanksTypeEnum } from 'onboarding';

export enum ScreensWithOxford {
  OXFORD_PAY = AdditionPurchaseTypeEnum.OXFORD_PAY,
  OXFORD_NOOM = AdditionPurchaseTypeEnum.OXFORD_NOOM,
  THANKS_WITH_WORKHEETS = ThanksTypeEnum.THANKS_WITH_WORKHEETS,
  E4K = AdditionPurchaseTypeEnum.E4K,
  E4K2 = AdditionPurchaseTypeEnum.E4K2,
  E4K_SECOND = AdditionPurchaseTypeEnum.E4K_SECOND,
  SP = AdditionPurchaseTypeEnum.SP,
}
