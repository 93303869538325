import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocalStorageModule } from 'angular-2-local-storage';

import { DeeplinkModelsFactory } from './models/deeplink.models.factory';
import { ProductModelsFactory } from './models/product.models.factory';
import { COMMON_SERVICES } from './services/services';

@NgModule({
  imports: [
    CommonModule,
    LocalStorageModule.forRoot({
      storageType: 'localStorage',
    }),
  ],

  providers: [
    ProductModelsFactory,
    DeeplinkModelsFactory,
    COMMON_SERVICES,
  ],
})
export class AppCommonModule {
}
