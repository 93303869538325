import { Action } from '@ngrx/store';
import { ActiveSubscription } from 'src/app/common/models/active-subscription';

import { LifeTimeProductStatusesEnum } from '../../common/enums/life-time-product-statuses.enum';

export enum ESubscriptionActions {
  UpdateSubscriptions = '[Subscription] Update Subscriptions',
  GetSubscriptionsFromServer = '[Subscription] Get Subscriptions From Server',
  GetSubscriptionsSuccess = '[Subscription] Get Subscriptions Success',
  UpdateLifeTimeProductStatus = '[Subscription] Update Life Time ProductStatus',
  SetOrderIdCard = '[Subscription] Set OrderId Card After Create Payment Form',
  SetOrderIdPaypal = '[Subscription] Set OrderId Paypal After Create Payment Form',
  SetOrderId = '[Subscription] Set OrderId After Payment',
  SetErrorOrderId = '[Subscription] Set Error OrderId After Payment',
  SetAdditionalDiscount = '[Subscription] Set Additional Discount',
  UpdateOxfordSubscription = '[Subscription] Update Oxford Subscription',
  UpdateE4KSubscription = '[Subscription] Update E4K Subscription',
  UpdateSPSubscription = '[Subscription] Update SP Subscription',
  SetSelectedPaymentMethod = '[Subscription] Set Payment Method',
  SetOrderMethod = '[Subscription] Set Order Method',
}

export class UpdateSubscriptions implements Action {
  public readonly type = ESubscriptionActions.UpdateSubscriptions;
  constructor(public payload: ActiveSubscription[]) {}
}

export class GetSubscriptionsFromServer implements Action {
  public readonly type = ESubscriptionActions.UpdateSubscriptions;
}

export class GetSubscriptionsSuccess implements Action {
  public readonly type = ESubscriptionActions.GetSubscriptionsSuccess;
  constructor(public payload: ActiveSubscription[]) {}
}

export class UpdateLifeTimeProductStatus implements Action {
  public readonly type = ESubscriptionActions.UpdateLifeTimeProductStatus;
  constructor(public lifeTimeProductStatus: LifeTimeProductStatusesEnum) {}
}

export class SetOrderIdCard implements Action {
  public readonly type = ESubscriptionActions.SetOrderIdCard;
  constructor(public orderId: string) {}
}

export class SetOrderIdPaypal implements Action {
  public readonly type = ESubscriptionActions.SetOrderIdPaypal;
  constructor(public orderId: string) {}
}

export class SetOrderId implements Action {
  public readonly type = ESubscriptionActions.SetOrderId;
  constructor(public orderId: string) {}
}

export class SetErrorOrderId implements Action {
  public readonly type = ESubscriptionActions.SetErrorOrderId;
  constructor(public errorOrderId: string) {}
}

export class SetAdditionalDiscount implements Action {
  public readonly type = ESubscriptionActions.SetAdditionalDiscount;
  constructor() {}
}

export class UpdateOxfordSubscription implements Action {
  public readonly type = ESubscriptionActions.UpdateOxfordSubscription;
  constructor() {}
}

export class UpdateE4KSubscription implements Action {
  public readonly type = ESubscriptionActions.UpdateE4KSubscription;
  constructor() {}
}

export class UpdateSPSubscription implements Action {
  public readonly type = ESubscriptionActions.UpdateSPSubscription;
  constructor() {}
}

export class SetSelectedPaymentMethod implements Action {
  public readonly type = ESubscriptionActions.SetSelectedPaymentMethod;
  constructor(public method: string) {}
}

export class SetOrderMethod implements Action {
  public readonly type = ESubscriptionActions.SetOrderMethod;
  constructor(public method: string) {}
}

export type SubscriptionActions =
UpdateSubscriptions |
GetSubscriptionsFromServer |
GetSubscriptionsSuccess |
UpdateLifeTimeProductStatus |
SetOrderIdCard |
SetOrderIdPaypal |
SetOrderId |
SetErrorOrderId |
SetAdditionalDiscount |
UpdateOxfordSubscription |
SetSelectedPaymentMethod |
UpdateE4KSubscription |
UpdateSPSubscription |
SetOrderMethod;
