import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { API_URL_GATEWAY } from 'src/app/api-service.config';
import { AuthService } from 'src/app/common/services/auth.service';
import { StorageService } from 'src/app/common/services/storage.service';
import { SubscriptionService } from 'src/app/common/services/subscription.service';
import { v4 as uuidv4 } from 'uuid';

export interface APIErrorResponse {
  code?: string;
  debugMsg?: string;
}

@Injectable()
export class ApiErrorsInterceptor implements HttpInterceptor {

  private errorList = new Set();

  private readonly storageIDKey = '_IK_storage_';

  constructor(
    private readonly storageService: StorageService,
    private readonly subscriptionService: SubscriptionService,
    private readonly auth: AuthService,
    private readonly httpClient: HttpClient,
    @Inject(API_URL_GATEWAY) private readonly api: string,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
  ) {
  }
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;
    if (this.auth.isAuthenticated()) {
      request  = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
          'x-intellectokids-id-token': this.auth.getToken()!,
        },
      });
    } else {
      request  = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
        },
      });
    }
    return next
      .handle(request)
      .pipe(
        map(event => event),
        catchError((error: HttpErrorResponse) => {
          const token = this.storageService.getRefreshToken();
          if ((error.status === 401 || error.status === 403) && !!token) {
            return this.subscriptionService
              .refreshToken(token.refreshToken)
              .pipe(
                switchMap(data => next.handle(request.method === 'POST' ? this.injectTokenAndOrderId(request, data.id_token, request.body) : this.injectToken(request, data.id_token))));
          }

          this.handleError(error);

          return throwError(() => error);
        }),
      );
  }

  private injectToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Access-Control-Allow-Origin': '*',
        'x-intellectokids-id-token': token,
      },
    });
  }

  private injectTokenAndOrderId(request: HttpRequest<any>, token: string, body: any): HttpRequest<any> {
    if (!!body.orderId) {
      body.orderId = uuidv4();
    }
    return request.clone({
      body,
      setHeaders: {
        'Access-Control-Allow-Origin': '*',
        'x-intellectokids-id-token': token,
      },
    });
  }

  private handleError(error: Error): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    let level = 'unknown';

    if ((error as any).error.payload && (error as any).error.payload.payment_provider_error_code) {
      return;
    }

    if (
      error.message.indexOf('subscriptions/get_available_products') !== -1 ||
      error.message.indexOf('subscriptions/create_purchase_payment_intent') !== -1 ||
      error.message.indexOf('subscriptions/create_pp_subscription') !== -1 ||
      error.message.indexOf('subscriptions/create_pp_purchase') !== -1 ||
      error.message.indexOf('subscriptions/recurring_payment') !== -1 ||
      error.message.indexOf('subscriptions/create_payment_intent') !== -1 ||
      error.message.indexOf('api/gateway/website-translations') !== -1
    ) {
      level = 'critical';
    }

    if (chunkFailedMessage.test(error.message)) {
      // window.location.reload();
      return;
    }
    if (this.errorList.has(error.message)) {
      return;
    }
    this.errorList.add(error.message);
    this.logError(error, level);
  }

  private async logError(error: Error, level: string): Promise<void> {
    // tslint:disable-next-line: no-console
    console.error(error);
    const indexOfURLSegment = error.message.indexOf("Error: Cannot match any routes. URL Segment: '");
    if (indexOfURLSegment !== -1) {
      const indexOfQuotes =  error.message.indexOf("'");
      const indexOfSlash = error.message.indexOf('/');

      if (indexOfSlash !== -1 && indexOfSlash > indexOfQuotes) {
        const path = error.message.substr(indexOfQuotes + 1, indexOfSlash - indexOfQuotes - 1);
        this.router.navigate([`/${ path }`]);
      } else {
        this.router.navigate(['']);
      }
    }
    if (error instanceof HttpErrorResponse) {
      this.errorHappened(error.message, `${ error.status } ${ error.statusText }`, level).subscribe();
      return;
    }

    this.errorHappened(error.message, error.stack || error.name || '', level).subscribe();
  }

  private errorHappened(message: string, error: string, level: string): Observable<string> {
    const user: {
      userId: string,
      email: string,
      variant: string,
    } = JSON.parse(this.localStorageService.get(this.storageIDKey));
    const body: any = {
      message,
      error,
      level,
      parameters: {
        userAgent: navigator.userAgent,
        browserId: user.userId,
        email: user.email ? user.email : '',
        variant: user.variant ? user.variant : '',
        link: window.location.href,
      },
    };

    return this.httpClient
      .post<string>(`${ this.api }/subscriptions/frontend_error`, body)
      .pipe(map(response => response));
  }

}
