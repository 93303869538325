import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { environment } from './../environments/environment.prod';
import { API_URL_GATEWAY } from './api-service.config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppCommonModule } from './common/common.module';
import { CustomTranslateLoader } from './common/services/custom-translate-loader.service';
import { ErrorLogService, GlobalErrorHandler } from './common/services/error-log.service';
import { ApiErrorsInterceptor } from './modules/api-errors/api-errors.interceptor';
import { ApiErrorsModule } from './modules/api-errors/api-errors.module';
import { PaymentLinkEffects } from './store/effects/payment-links.effects';
import { ProductEffects } from './store/effects/product.effects';
import { SubscriptionEffects } from './store/effects/subscription.effects';
import { UserInfoEffects } from './store/effects/user-info.effects';
import { VariantEffects } from './store/effects/variant.effects';
import { appReducers } from './store/reducers/app.reducers';
import { CustomCurrencyModule } from './ui/currency/custom-currency.module';
import { TitleService } from './ui/title/title.service';

// export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
//   return new TranslateHttpLoader(http, '/api/gateway/website-translations/', `.json?cb=${ (new Date()).getTime() }`);
// }

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AppCommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ApiErrorsModule,
    HammerModule,
    CustomCurrencyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([
      SubscriptionEffects,
      UserInfoEffects,
      ProductEffects,
      PaymentLinkEffects,
      VariantEffects,
    ]),
    StoreRouterConnectingModule.forRoot({ stateKey : 'router' }),
    StoreDevtoolsModule.instrument(),
    NgxPageScrollCoreModule,
    // !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],

  providers: [
    TitleService,
    {
      provide: API_URL_GATEWAY,
      useValue: environment.gateway,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorsInterceptor,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig,
    },
    ErrorLogService,
  ],

  declarations: [
    AppComponent,
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule { }
