import { LanguageModel } from './../models/language-model';

export const LOCALIZATIONS: LanguageModel[] = [
  new LanguageModel('ENG', 'en'),
  new LanguageModel('MEX', 'es-MX'),
  new LanguageModel('FRA', 'fr'),
  new LanguageModel('ITA', 'it'),
  new LanguageModel('BRA', 'pt-BR'),
  new LanguageModel('DEU', 'de'),
  new LanguageModel('DUT', 'nl'),
  new LanguageModel('SWE', 'sv'),
  new LanguageModel('IDN', 'id'),
  // new LanguageModel('RUS', 'ru'),
  new LanguageModel('DAN', 'da'),
  new LanguageModel('NOR', 'no'),
  new LanguageModel('ESP', 'es'),
  new LanguageModel('POR', 'pt'),
  new LanguageModel('CZE', 'cs'),
  new LanguageModel('HUN', 'hu'),
  new LanguageModel('GRE', 'el'),
  new LanguageModel('SLK', 'sk'),
  new LanguageModel('BUL', 'bg'),
  new LanguageModel('POL', 'pl'),
  new LanguageModel('RUM', 'ro'),
  new LanguageModel('KLT', 'kl'),
];
